import axios from 'axios';
import { Modal } from 'antd';
import * as actionsAuth from '../../redux/actions/auth';
import { connect } from 'react-redux';

export const makeRequest = (property, hostName = process.env.REACT_APP_BACKEND_API, 
  includeXapiKey = false) => {
  if (property.hostName) {
    hostName = property.hostName;
  }

  const token = 'Bearer ' + sessionStorage.getItem('token');

  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  axios.defaults.xsrfCookieName = 'csrftoken';

  let headers = {
    'Content-Type': 'application/json',
    Authorization: token ? token : '',
    ...property.headers,
  };

  // Add x-api-key to the headers if not already included and if includeXapiKey is true
  if (includeXapiKey && !headers['x-api-key']) {
    const browserUrlParams = new URLSearchParams(window.location.search);
    const customer = browserUrlParams.get('customer');
  
    headers['x-api-key'] = customer || sessionStorage.getItem('x-api-key');

  }

  let config = {
    headers: headers,
    params: property.params ? property.params : '',
    withCredentials: true // Add this line to include credentials in the request
  };

  // Compute the full request URL once
  const requestFullUrl = `${hostName.replace(/\/$/, '')}/${(property.url || '').replace(/^\//, '')}`;
  switch (property.method) {
    case 'auth':
      return axios.post(requestFullUrl, property.body).catch((error) => {
        Modal.error({
          title: 'Error Auth',
          content: error.response ? error.response.data : error.message,
        });
        return null;
      });

    case 'get':
      return axios.get(requestFullUrl, config).catch(commonCatch);

    case 'options':
      return axios.options(requestFullUrl, config).catch(commonCatch);

    case 'download':
      return axios
        .get(requestFullUrl, { ...config, responseType: 'blob' })
        .catch(commonCatch);

    case 'postdownload':
      return axios
        .post(requestFullUrl, property.body, { ...config, responseType: 'blob' })
        .catch(commonCatch);

    case 'post':
      return axios.post(requestFullUrl, property.body, config).catch(commonCatch);

    case 'put':
      return axios.put(requestFullUrl, property.body, config).catch(commonCatch);

    case 'delete':
      return axios.delete(requestFullUrl, config).catch(commonCatch);

    default:
      console.warn('method type not exist');
      return Promise.resolve(null);
  }
};

function commonCatch(error) {


  if (error.response && error.response.status === 401) {

    localStorage.removeItem('code');
    sessionStorage.removeItem('token');
    localStorage.removeItem('userRole');
    //actionsAuth.logout();
    this.props.onTryAutoSignup();
  } else {
   
    //console.log('ERROR', error);
    //console.log('ERROR', error.response);
    //Modal.error({
    //  title: error.response ? 'Error: ' + error.response.config.url : 'Error: ',
    //  content: error.response ? error.response.data.MSG : error.message,
    //});
  }
  throw error;
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actionsAuth.authCheckState()),
  };
};

export default connect(mapDispatchToProps)(commonCatch);
