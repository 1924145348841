import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { Modal, Row, Col, Input, message, Tooltip } from 'antd';
import { MdOutlineClear } from 'react-icons/md';
import { BaseLayoutContext } from 'layouts/baseLayout/BaseLayoutProvider';
import styled from 'styled-components';


const ManageDelegationModal = ({ personalAssistant, segmentId }) => {
  const { isSettingsWindowOpen, changeSettingsWindowVisibility } = useContext(BaseLayoutContext);
  const [inputValue, setInputValue] = useState(personalAssistant);

  const handleCancel = () => {
    changeSettingsWindowVisibility();
  };

  const handleOk = async () => {
    try {
      const token = 'Bearer ' + sessionStorage.getItem('token');
      const mail = sessionStorage.getItem('mail'); // Retrieve mail from sessionStorage
      const xapikey = sessionStorage.getItem('x-api-key'); // Retrieve xapikey from sessionStorage

      // Check if the browser URL has a 'customer' parameter
      const browserUrlParams = new URLSearchParams(window.location.search);
      const customer = browserUrlParams.get('customer');

      // Prepare headers
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
        'user': mail, // Add mail to headers
        'x-api-key': xapikey, // Add xapikey to headers
      };

      // If 'customer' parameter exists in the browser URL, add it to the headers
      if (customer && !xapikey) {
        headers['x-api-key'] = customer;
      }

      // Make the request with the headers
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_V3}/api/v3/trip/questionnaire/delegate-emails/${segmentId}`,
        { email: inputValue },
        { headers: headers }
      );

      message.success('Trip is correctly delegated ');

      setTimeout(() => {
        changeSettingsWindowVisibility();
        window.location.reload();
      }, 500);
      
    } catch (error) {
      console.error('Error:', error);
      // Handle the error
      Modal.error({
        title: 'Error',
        content: 'Something went wrong. Please try again later.',
      });
    }
  };

  useEffect(() => {
    setInputValue(personalAssistant);
  }, [personalAssistant]);

  return (
    <Modal
      width={650}
      title="Delegation"
      visible={isSettingsWindowOpen}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Title>Manage delegation for this myTrips Plus questionnaire</Title>
        </Col>

        <Col span={12}>Your preset myTrips+ delegate</Col>
        <Col span={12}>{personalAssistant}</Col>

        <Col span={12}>Delegate this trip to</Col>
        <Col span={10}>
          <Input size="small" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </Col>
        <Col span={2}>
          <Tooltip
            color="white"
            overlayInnerStyle={{ color: 'black' }}
            title="Click here to clear the delegate"
          >
            <MdOutlineClear style={{ cursor: 'pointer' }} onClick={() => setInputValue('')} />
          </Tooltip>
        </Col>
      </Row>
    </Modal>
  );
};

export default ManageDelegationModal;

const Title = styled.div`
  color: black;
  font-weight: bold;
`;
