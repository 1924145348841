import React, { useEffect, useState } from 'react'
import { Form, Button, Collapse, Col, Row, Modal, Select, Checkbox } from 'antd'
import FormBuilder from 'antd-form-builder'
import { makeRequest } from 'features/communication/request'
import { useLocation } from 'react-router-dom';

export default function PWDManualSubmission({ setIsSubmissionDone })
{
  const [form] = Form.useForm()
  const [viewMode, setViewMode] = useState(true)
  const [values, setvalues] = useState([])
  const [pwdDateFilter, setPwdDateFilter] = useState([])
  const [isAccepted, setIsAccepted] = useState(false)
  const [pwdSubmission, setPwdSubmission] = useState([])
  const [selectedSubmissionDate, setSelectedSubmissionDate] = useState()
  const [submissionStatus, setSubmissionStatus] = useState()
  const { state } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(sessionStorage.getItem('isOpen'));
  useEffect(() =>
  {
    sessionStorage.removeItem('isOpen')
  }, [isModalOpen])
  const Panel = Collapse.Panel;


  const handleFinish = async (pwdEdit, status) =>
  {
    form.resetFields();
    setvalues([])
    try
    {
      await makeRequest({
        hostName: process.env.REACT_APP_BACKEND_API_V3,
        url: `/api/v2/pwd/rpasubmissions`,
        method: 'post',
        body: {
          tripId: state?.tripid,
          submissions: pwdEdit,
          createdOn: new Date().toISOString().replace('Z', ''),
          createdBy: sessionStorage.getItem('mail'),
          status: status
        },
        headers: {
          'x-api-key': sessionStorage.getItem('x-api-key'),
        },
      }).then((res) =>
      {
        setViewMode(true)
        setIsSubmissionDone()
      }).catch((error) => error)
    } catch (error)
    {
      return error
    }
    sessionStorage.removeItem('isOpen')
  }



  const getPWDForm = async () =>
  {
    form.resetFields();
    let pwdSubmissionForm = {}
    let data = []
    let createdDates = []
    try
    {
      await makeRequest({
        hostName: process.env.REACT_APP_BACKEND_API_V3,
        url: `/api/v2/pwd/rpasubmissions/${state?.tripid}?includeAudit=true`,
        method: 'get',
        headers: {
          'x-api-key': sessionStorage.getItem('x-api-key'),
        },
      }).then((res) =>
      {
        setPwdSubmission(res.data.value)
        setSubmissionStatus(res.data.value[0].status)
        setSelectedSubmissionDate(res.data.value[0].createdOn)
        Object.keys(res.data.value[0].submissions).map(key =>
        {
          pwdSubmissionForm['key'] = key
          pwdSubmissionForm['label'] = key
          pwdSubmissionForm['initialValue'] = res.data.value[0].submissions[key]
          pwdSubmissionForm['rules'] = [
            {
              validator: (rule, value, callback) =>
              {
                return new Promise((resolve, reject) =>
                {
                  if (value !== res.data.value[0].submissions[key])
                  {
                    reject(new Error('Previous value: ' + res.data.value[0].submissions[key]))
                  } else
                  {
                    resolve()
                  }
                })
              },
            },
          ]
          pwdSubmissionForm['colSpan'] = 4
          data.push(pwdSubmissionForm)
          pwdSubmissionForm = {}

        });
        res.data.value.map((val) =>
        {
          const dates = { value: val.createdOn, label: val.createdOn }
          createdDates.push(dates)
        })
        setPwdDateFilter(createdDates)
        setvalues(data)
      }).catch((error) =>
      {
        //console.log(error)
      })
    } catch (error)
    {
      //console.log(error)
    }
  }

  useEffect(() =>
  {
    if (sessionStorage.getItem('isOpen') === 'true')
    {
      setvalues([])
      form.resetFields();
      getPWDForm()
      setIsAccepted(false)
    }
  }, [sessionStorage.getItem('isOpen')])


  const handleCancel = () =>
  {
    sessionStorage.removeItem('isOpen')
    setIsModalOpen(false);
    setViewMode(true)
  };

  const isConfirmed = (e) =>
  {
    if (e.target.checked)
    {
      setIsAccepted(true)
    } else
    {
      setIsAccepted(false)
    }

  }

  const handleSubmit = (status) =>
  {
    let form_vals = form.getFieldsValue();
    handleFinish(form_vals, status)
    handleCancel()
  }

  const handleSelect = (value) =>
  {
    setViewMode(true)
    setSelectedSubmissionDate(value)
    let pwdSubmissionForm = {}
    let data = []
    const selectedSubmission = pwdSubmission.filter((submission) =>
    {
      if (submission.createdOn === value)
      {
        setSubmissionStatus(submission.status)
        return submission
      }
    })

    Object.keys(selectedSubmission[0].submissions).map(key =>
    {
      pwdSubmissionForm['key'] = key
      pwdSubmissionForm['label'] = key
      pwdSubmissionForm['initialValue'] = selectedSubmission[0].submissions[key]
      pwdSubmissionForm['rules'] = [
        {
          validator: (rule, value, callback) =>
          {
            return new Promise((resolve, reject) =>
            {
              if (value !== selectedSubmission[0].submissions[key])
              {
                reject(new Error('Previous value: ' + selectedSubmission[0].submissions[key]))
              } else
              {
                resolve()
              }
            })
          },
        },
      ]
      pwdSubmissionForm['colSpan'] = 4
      data.push(pwdSubmissionForm)
      pwdSubmissionForm = {}

    });

    setvalues(data)
  }
  const meta = {
    columns: 4,
    formItemLayout: null, // Must set this for inline layout
    colon: true,
    fields: values
  }
  return (

    <Modal title="PWD Resubmission" maskClosable={false} footer={null} open={sessionStorage.getItem('isOpen')} width={'80%'} onCancel={handleCancel} className={meta.fields.length > 0 ? 'full_height' : ''} >

      <Collapse defaultActiveKey={['1']} style={{ marginTop: '20px', margin: '20px', position: 'relative' }}>
        <Panel header="Trip Details" key="1">
          <Row className='col_gap'>
            <Col span={4} ><div>First Name</div></Col>
            <Col span={8}><div>{state?.firstname}</div></Col>
            <Col span={4}><div>Last Name</div></Col>
            <Col span={8}><div>{state?.lastname}</div></Col>

            <Col span={4}><div>Email</div></Col>
            <Col span={8}><div>{state?.emailaddress}</div></Col>
            <Col span={4}><div>TripId</div></Col>
            <Col span={8}><div>{state?.tripid}</div></Col>

            <Col span={4}><div>Nationality</div></Col>
            <Col span={8}><div>{state?.primarynationalitycountry}</div></Col>
            <Col span={4}><div>Status</div></Col>
            <Col span={8}><div>{state?.status ?? 'N/A'}</div></Col>

          </Row>

        </Panel>
      </Collapse>

      <Collapse defaultActiveKey={['2']} style={{ marginTop: '20px', margin: '20px', position: 'relative' }}>

        <Panel header={'PWD Registration Form'} key="2" className='pwd_panel'>
          <div style={{ marginBottom: '68px' }}>
            {state && state?.pwdstatus === 'PWDRPAFailed' && pwdDateFilter[0]?.value === selectedSubmissionDate ? <Button type="primary" className='btn_primary_pwddoc' style={{ borderRadius: '5px !important' }} onClick={() => setViewMode(false)} htmlType="submit">
              Edit
            </Button> : ''}
            <div style={{ float: 'right' }}>
              <label style={{ marginRight: '20px' }} ><b>Submission date:</b></label>
              <Select
                value={selectedSubmissionDate}
                style={{ width: '260px' }}
                onChange={handleSelect}
                options={pwdDateFilter}
              />
            </div>
          </div>
          <div>
            <table className='table table-bordered' style={{ marginBottom: '32px' }}>
              <tr>
                <td style={{ width: '54%' }}>
                  <label><b>Submitted by:</b></label>
                  <span style={{ marginLeft: '10px' }}>{sessionStorage.getItem('mail')}</span>
                </td>
                <td>
                  <label> <b>Submission status:</b></label>
                  <span style={{ marginLeft: '10px' }}>{submissionStatus}</span>
                </td>
              </tr>
            </table>
          </div>
          <Form layout="vertical" className="pwdform" form={form} >
            <FormBuilder form={form} meta={meta} viewMode={viewMode} />
            {state && state?.pwdstatus === 'PWDRPAFailed' && !viewMode && <Form.Item wrapperCol={{ span: 16 }}>
              <Checkbox onChange={isConfirmed}><b>I confirm that I have reviewed the changes and it is ready for submission</b></Checkbox>
            </Form.Item>
            }
            <Form.Item wrapperCol={{ span: 16, offset: 7 }}>
              {state && state?.pwdstatus === 'PWDRPAFailed' && !viewMode && <Button style={{ marginRight: '20px' }} disabled={isAccepted ? false : true} onClick={() => handleSubmit('ManualProcessing')} type="primary" htmlType='submit'>
                Manual Processing
              </Button>}
              {state && state?.pwdstatus === 'PWDRPAFailed' && !viewMode && <Button disabled={isAccepted ? false : true} type="primary" onClick={() => handleSubmit('InQueue')} htmlType='submit'>
                Send to RPA
              </Button>}
            </Form.Item>
          </Form>
        </Panel>

      </Collapse>
    </Modal>

  )
}