import { makeRequest } from 'features/communication/request';
import moment from 'moment';
import {itIsEmail, itIsPNR} from "../components/Table/table.definition";
import history from "../../../features/routing/history";

const downloadFile = (uuid, file) => {
  makeRequest({
    method: 'download',
    url: '/api/v3/document/download/' + uuid,
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  }, undefined, true // requires x api key for download
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url; 
    link.setAttribute('download', file);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const getDropdownItems = () => {
  const promise = makeRequest({
    method: 'options',
    url: '/api/v3/document',
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  });

  return promise.then((response) => response.data);
};

const getUserFile = (uuid) => {
  const promise = makeRequest({
    method: 'get',
    url: '/api/v3/document/files',
    params: {uuid, }, // filter all the files against uuid / document id 
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  }, undefined, true); // requires x api key for getting record

  return promise.then((response) => response.data);
};

const getUserFiles = (userEmail, paginationState, filtersState, sorterState) => {
  const sorterParams = sorterState.sort_direction ? sorterState : null;
  const query = new URLSearchParams(history.location.search);

  let params = {
    pageNumber: paginationState,
    pageSize: 10,
    ...sorterParams, //sorting not implemented yet in backend apis
  };
  
  if (query.has("email")) {
    params.email = query.get("email");
  }

  // Define the filter fields and their corresponding values
  const filters = {
    pnr: itIsPNR(filtersState.pnr.state) ? filtersState.pnr.state : null,
    mail: itIsEmail(userEmail) ? userEmail : null,
    obligationAndDocumentType: filtersState.obligationAndDocumentType.state,
    destinationCountry: filtersState.destinationCountry.state,
    departureDate: filtersState.departureDate.state && moment(filtersState.departureDate.state).format('YYYY-MM-DD'),
    tripId: filtersState.tripId.state
  };

  // Add only the provided filter fields to params
  Object.keys(filters).forEach(key => {
    if (filters.hasOwnProperty(key) && filters[key] !== null) {
      params[key] = filters[key];
    }
  });

  // no need of filtering out email based tripfiles as backend already does that
  if (itIsPNR(userEmail)) { 
    params.pnr = userEmail; // re-write pnr to the query parameters
  }

  const promise = makeRequest({
    method: 'get',
    url: '/api/v3/document/files',
    params,
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  }, undefined, true);

  return promise.then((response) => response.data);
};


const uploadFile = (
  customer,
  { file, tripId, mail, pnr, destinationCountry, departureDate, obligationAndDocumentType, mswCountries }
) => {
  const params = {
    tripId,
    mail,
    pnr,
    destinationCountry,
    departureDate: moment(new Date(Date.parse(departureDate))).format('DD/MM/YYYY'),
    obligationAndDocumentType,
    mswCountries
  };

  const formData = new FormData();
  formData.append('file', file);

  // Append all fields from params to formData
  Object.keys(params).forEach(key => {
    formData.append(key, params[key]);
  });

  const promise = makeRequest({
    method: 'post',
    url: '/api/v3/document',
    body: formData,
    headers: {
      'x-api-key': customer,
    },
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  });

  return promise.then((response) => response.data);
};

//For Admin Action
const getClients = () => {
  const promise = makeRequest({
    hostName: process.env.REACT_APP_BACKEND_API,
    method: 'options',
    url: '/v1/uploadfile/',
  });

  return promise.then((response) => response.data);
};

const getV3Clients = () => {
  const promise = makeRequest({
    method: 'options',
    url: '/api/v3/trips/customers',
    hostName: process.env.REACT_APP_BACKEND_API_V3,
  });

  return promise.then((response) => response.data);
};

const API = {
  getUserFile,
  getUserFiles,
  getDropdownItems,
  downloadFile,
  uploadFile,
  getClients,
  getV3Clients
};

export default API;
