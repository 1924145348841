import React, { Component } from 'react';
import { Modal } from 'antd';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import { makeRequest } from 'features/communication/request';
 // Import the global dictionary

class MainPage extends Component {
  state = {
    windowOpened: false,
    email: '',           // Email address from sessionStorage
    tripsData: [],       // Data received from the GET API
    loading: true,       // Loading state for the API call
    error: null,         // Error state in case the API call fails
  };

  // Method to fetch data from the API using axios
  fetchTrips = async (email) => {
    try {
      const mail = sessionStorage.getItem('mail'); // Retrieve mail from sessionStorage
      const params = { EmailAddress: email, Status: 'OnlineForm' }; // Query parameters

      // Prepare headers
      const headers = {
        'user': mail, // Add mail to headers
      };

    // Construct the property object
    const property = {
      method: 'get',
      url: '/api/v3/trip/questionnaire/forms',
      headers: headers,
      params: params,
      hostName: process.env.REACT_APP_BACKEND_API_V3
    };

    // Call makeRequest
    const response = await makeRequest(property, undefined, true);
      // Update state with the fetched data
      this.setState({ tripsData: response.data, loading: false });
    } catch (error) {
      // Modal.error({
      //   title: 'Error',
      //   content: 'Something went wrong. Please try again later.',
      // });
      this.setState({ error: error.message, loading: false });
    }
  };

  // Lifecycle method to get email from sessionStorage and call API
  async componentDidMount() {
    //console.log('MainPage componentDidMount');
    const email = sessionStorage.getItem('mail'); // Retrieve email from sessionStorage
    if (email) {
      //console.log('MainPage componentDidMount email:', email);
      await this.fetchTrips(email);
      this.setState({ email });
    } else {
      //console.log('MainPage componentDidMount No email found in sessionStorage');
      this.setState({ loading: false, error: 'No email found in sessionStorage' });
    }
  }

  render() {
    const { loading, tripsData, error } = this.state;

    return (
      <BaseLayout>
        <div onMouseMove={this.HandleMove} style={{ padding: '20px' }}>
          <div className='admin_cards_wrapper col d-flex justify-content-center'>
          <p className='card-landing'>Welcome to Vialto Partners downstream business travel compliance technology.
            To complete travel questionnaire, use the full website link previously provided.
            Please contact your Vialto Partners engagement team with any questions.
            For more information on Vialto Partners travel compliance solutions click <a href="https://vialto.com/" target="_blank" style={{ display: 'contents', color: '#0A838A' }}>here</a></p>
          </div>
          {/* Display the cards if data is available */}
          {!loading && !error && tripsData.length > 0 && (
            <div className='admin_cards_wrapper col d-flex justify-content-center'>
              {tripsData.map((trip, index) => {
                const browserUrlParams = new URLSearchParams(window.location.search);
                const customer = browserUrlParams.get('customer');
                let link = trip.link;

                if (customer) {
                  const url = new URL(trip.link);
                  url.searchParams.append('customer', customer);
                  link = url.toString();
                }

                return (
                  <div key={index} className='card'>
                    <div className='card-body'>
                      {/* <h5 className='card-title'>Trip {index + 1}</h5> */}
                      <h5 className='card-title'>{trip.recordLocator}</h5>
                      <p className='card-text'><strong>Trip ID:</strong> {trip.tripId}</p>
                      {/* <p className='card-text'><strong>Record Locator:</strong> {trip.recordLocator}</p> */}
                      <p className='card-text'><strong>Home Office:</strong> {trip.homeCountry}</p>
                      <p className='card-text'><strong>From Country:</strong> {trip.from}</p>
                      <p className='card-text'><strong>To Country:</strong> {trip.to}</p>
                      <p className='card-text'><strong>Departure Date:</strong> {new Date(trip.departureDate).toLocaleDateString()}</p>
                      <p className='card-text'><strong>Obligation:</strong> {trip.service}</p>
                      <a href={link} target="_self" rel="noopener noreferrer" className='btn btn-primary'>Open Link</a>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* Show a message if no trips were found */}
          {!loading && !error && tripsData.length === 0 && (
            <p>No trips found for the given email address.</p>
          )}
        </div>
      </BaseLayout>
    );
  }
}

export default MainPage;
