import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import UploadDocumentPage from './Pages/UploadDocumentPage';
import TravelCompliancePage from './Pages/TravelCompliancePage';
import MainPage from './Pages';
import Kibana from './Pages/kibana';
import MyTrips_v3 from './Pages/myTrips_v3';
import MyTrips from './Pages/myTrips';
import Logout from './Pages/logout';
import { AuthService } from './services/authService';
import * as actionsAuth from './redux/actions/auth';
import { connect } from 'react-redux';
import { LoginRoute } from './features/routing/loginRoute';
import { DecisionRoute } from './features/routing/decisionRoute';
import { PrivateRoute } from './features/routing/privateRoute';
import { makeRequest } from 'features/communication/request';

import { IdleTimeOutModal } from './components/IdleModal';
import IdleTimer from 'react-idle-timer';
import packageJson from '../package.json';
import VersionCheck from "./components/VersionCheck";
import useAdmin from './redux/actions/auth';
import AdminActions from 'Pages/Admin/AdminAction';
import PWDManualSubmission from 'Pages/Admin/PWDManualSubmission';
import AdminConsoles from 'Pages/AdminConsole/AdminConsole';

const IDLE_TIMEOUT = 10 * 60 * 1000;
const LOGOUT_TIMEOUT = 60 * 1000;

class App extends Component {
  constructor(props) {
    super();
    var dotenv = require('dotenv');
    var dotenvExpand = require('dotenv-expand');
    var myEnv = dotenv.config();
    dotenvExpand(myEnv);
    global.appVersion = packageJson.version;

    this.state = {
      isLoading: false,
      setLoading: (isLoading) => this.setState({ isLoading }),
      timeout: IDLE_TIMEOUT,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false, timeout: IDLE_TIMEOUT });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false, timeout: IDLE_TIMEOUT });
  }

  

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      actionsAuth.logout();
    } else {
      this.setState({ showModal: true, timeout: LOGOUT_TIMEOUT });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleClose() {
    this.setState({ showModal: false, timeout: IDLE_TIMEOUT });
  }

  handleLogout() {
    this.setState({ showModal: false });
    actionsAuth.logout();
  }

  componentDidMount(){
  this.props.onTryAutoSignup();
}
  
  
  render() {
    return (
      <>

        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />

        <IdleTimeOutModal
          showModal={this.state.showModal}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
        <Router>
          <Switch>
            <LoginRoute
              path={'/login'}
              onLoggedIn={() => <Redirect to={localStorage.getItem('path')} />}
              onNotLogged={() =>
                AuthService.processExternalAuthentication(this.props.onProcessToken)
              }
            />
            <PrivateRoute path={process.env.REACT_APP_CLIENT + '/form3/:id'} component={MyTrips_v3} />
            <PrivateRoute path={process.env.REACT_APP_CLIENT + '/form2/:id'} component={MyTrips} />                        
            <Route exact path={process.env.REACT_APP_CLIENT + '/logout'} component={Logout} />
            <PrivateRoute
              exact
              path={process.env.REACT_APP_CLIENT + '/travel-compliance/:id'}
              component={TravelCompliancePage}
            />
            <PrivateRoute
              exact
              path={process.env.REACT_APP_CLIENT + '/travel-compliance'}
              component={TravelCompliancePage}
            />
            <PrivateRoute
              exact
              path={sessionStorage.getItem('isAdmin') === 'Admin' ? process.env.REACT_APP_CLIENT + '/upload-document' : '/'}
              component={sessionStorage.getItem('isAdmin') === 'Admin' ? UploadDocumentPage : MainPage}
            />
            <PrivateRoute exact  path={sessionStorage.getItem('isAdmin') === 'Admin' ? process.env.REACT_APP_CLIENT + '/PWDManualSubmission' : '/'} component={sessionStorage.getItem('isAdmin') === 'Admin' ? PWDManualSubmission : MainPage}/>
            <PrivateRoute exact path={sessionStorage.getItem('isAdmin') === 'Admin' ? process.env.REACT_APP_CLIENT + '/AdminAction' : <Redirect to="/" />} component={sessionStorage.getItem('isAdmin') === 'Admin' ? AdminActions : MainPage} />
            <PrivateRoute exact path={sessionStorage.getItem('isAdmin') === 'Admin' ? process.env.REACT_APP_CLIENT + '/AdminConsole' : <Redirect to="/" />} component={sessionStorage.getItem('isAdmin') === 'Admin' ? AdminConsoles : MainPage} />
            <Route component={MainPage} />

            <DecisionRoute
              trueComponent={() => <Redirect to="/" />}
              falseComponent={() => <Redirect to="/login" />}
              decisionFunc={() => AuthService.isAuthenticated()}
            />
          </Switch>
        </Router>
        <VersionCheck>
          {({ loading, isLatestVersion, refresh }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refresh();
            }
            return (null)
          }}
        </VersionCheck>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onProcessToken: (code) => dispatch(actionsAuth.authOFISLogin(code)),
    onTryAutoSignup: () => dispatch(actionsAuth.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
